.main {
    .window-card {
        width: 100vw;
        height: 100vh;

        position: absolute; 
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;

        pointer-events: none;

        .window-card-container {
            width: 92%;
            height: 92%;
            max-width: 1227.6px;
            max-height: 693px;

            background-color: rgba(255, 255, 255, 0.87);
            backdrop-filter: blur(15px);
            box-sizing: border-box;

            display: flex;
            align-items: center;
            justify-content: center;

            pointer-events: all;

            position: relative;

            transition: 0.2s;

            .window-card-cross {
                width: 24px;
                height: 24px;
                
                position: absolute;
                z-index: 5;
                top: 16.5px;
                right: 20px;

                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;
                
                transition: 0.2s;

                img {
                    width: 24px;
                }
            }

            .window-card-inner {
                width: calc(100% - 6px);
                height: calc(100% - 6px);

                border-radius: 15px;

                .window-card-gallery {
                    width: 100%;
                    height: 100%;
                    
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                    
                    border-radius: 10px;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    transition: 0.2s;

                    .window-card-gallery-arrow {
                        height: 80%;
                        z-index: 2;

                        cursor: pointer;

                        display: flex;
                        align-items: center;

                        user-select: none;

                        img {
                            width: 15px;
                            transition: 0.2s;
                            
                            user-select: none;
                        }
                    }

                    .window-card-gallery-arrow-left {
                        padding-right: 25%;
                        img {
                            margin-left: 15px;
                        }
                    }

                    .window-card-gallery-arrow-right {
                        padding-left: 25%;
                        img {
                            margin-right: 15px;
                        }
                    }

                    .window-card-gallery-back {
                        width: 100%;
                        height: 100%;
                        
                        border-radius: 10px;

                        position: fixed;
                        z-index: 1;
                    }

                    .window-card-gallery-circles {
                        width: 100%;
                        bottom: 35px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        position: fixed;
                        z-index: 2;
                        
                        .window-card-gallery-circles-inner {
                            display: flex;

                            .window-card-gallery-circle {
                                width: 10px;
                                height: 10px;
                                margin-right: 7px;
        
                                border-radius: 100px;
        
                                transition: 0.2s;
                                cursor: pointer;
        
                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }

                .window-card-bird {
                    width: 100%;
                    height: 100%;

                    overflow-x: auto;
                    overflow-y: hidden;

                    position: relative;

                    .window-card-bird-inner {
                        height: 100%;
                        width: auto;

                        transition: 0.2s;
                        transform-origin: 0 0;

                        .window-card-bird-bg {
                            height: 100%;
                            width: auto;
    
                            transition: 0.2s;
                            user-select: none;
                        }
                    }

                    .window-card-bird-project {
                        position: relative;
                        transition: 0.2s;
                        
                        .window-card-bird-project-label {
                            position: absolute;
                            z-index: 20;

                            img {
                                cursor: pointer;
                                transition: 0.2s;
                            }
                        }

                        .window-card-bird-project-line {
                            position: absolute;

                            svg {
                                transition: 0.2s;
                            }
                        }

                        .window-card-bird-project-polygon {
                            position: absolute;

                            svg path {
                                cursor: pointer;
    
                                transition: 0.3s;
                            }
                        }

                    }

                    @media (min-aspect-ratio: 1.777777777777778) {
                        overflow-x: hidden !important;
                        overflow-y: auto !important;

                        .window-card-bird-inner {
                            width: 100% !important;
                            height: auto !important;

                            .window-card-bird-bg {
                                width: 100% !important;
                                height: auto !important;
                            }

                        }
                    }
                }

                .window-card-video {
                    width: 100%;
                    height: 100%;

                    video {
                        width: 100%;
                        height: 100%;
                    }
                }

                .window-card-outside-button {
                    margin: 0 auto;
                    margin-top: 10px;

                    width: 100%;
                    max-width: 200px;
                    height: 56px;

                    background-color: #FFFFFF;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    cursor: pointer;

                    .window-card-outside-button-text {
                        
                        color: #000000;
                        font-family: Graphik Arabic;
                        font-size: 12px;
                        font-weight: 500;
    
                        letter-spacing: 1px;
                        text-transform: uppercase;
                    }
                }

                video {
                    border-radius: 15px;
                }

                iframe {
                    border-radius: 15px;
                }
            }
        }
    }
}