.main {

    .header {

        .header-container {

            .header-panel {
                .header-panel-bottom {
                    .header-guide {
                        .header-guide-button:hover {
                            background-color: #0dc298 !important;
                        }
                    }
                }
            }

            .header-right {
                
                .header-dropdown {

                    .header-dropdown-button:hover {
                        // background-color: rgb(223, 223, 223);
                        background-color: rgb(247, 247, 247)
                    }

                    .header-dropdown-list {
                        .header-dropdown-list-element:hover {
                            // background-color: rgb(249, 249, 249);
                        }
                    }
                }
            }
        }
    }

    .card {
        .card-container {

            .card-exit:hover {
                img {
                    transform: scale(1.1) ;
                }
            }

            .card-bottom {
                .card-bottom-button:hover {
                    background-color: #2c4c530c;

                    .card-bottom-button-inner {
                        img {
                            transform: translate(2px);
                        }
                    }
                }
            }
        }
    }

    .window-card {
        .window-card-container {

            .window-card-cross:hover {
                transform: scale(1.03);
            }

            .window-card-gallery-arrow:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    .small-card {
        .small-card-container {

            .small-card-exit:hover {
                transform: scale(1.05);
            }

            .small-card-elements {
                .small-card-elements-button:hover {
                    background-color: #2c4c530c;

                    img {
                        transform: translate(2px);
                    }
                }
            }
        }
    }

    .guide-mobile, .guide {
        .guide-button:hover {
            background-color: #0dc298 !important;
        }

        .guide-button-transparent:hover {
            background-color: #f5f5f5 !important;
        }
    }
}