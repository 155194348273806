.main {
    .small-card {
        position: absolute;
        z-index: 30;

        width: 100vw;
        height: 100vh;

        pointer-events: none;

        overflow: hidden;

        .small-card-container {
            position: relative;

            max-width: 400px;
            width: 100%;

            background-color: #FFFFFF;

            display: flex;
            align-items: center;
            justify-content: space-between;

            transition: 0.2s;

            .small-card-exit {
                position: absolute;
                
                top: 4px;
                right: 4px;
                
                width: 20px;
                height: 20px;

                background-color: #ffffffd2;

                display: flex;
                align-items: center;
                justify-content: center;

                cursor: pointer;
                
                transition: 0.1s;

                img {
                    width: 14px;
                }
            }

            .small-card-deyaar-circle {
                position: absolute;
                pointer-events: none;

                bottom: 0;
                right: 0;

                width: 88px;
                height: 84px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .small-card-preview {
                max-width: 145px;
                width: 100%;
                height: 100%;

                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }

            .small-card-elements {
                max-width: 229px;
                width: 100%;
                max-height: 229px;

                .small-card-elements-title {
                    font-family: Graphik Arabic;
                    font-size: 22px;

                    margin-bottom: 2px;
                }

                .small-card-elements-desc {
                    font-family: Helvetica;
                    font-size: 11px;
                    font-weight: 300;

                    line-height: 14px;

                    margin-bottom: 9px;

                    height: 50px;
                    overflow-y: auto;
                }

                .small-card-elements-button {
                    max-width: 134px;
                    width: 100%;
                    height: 32px;

                    box-sizing: border-box;
                    border: 1px solid #1F4546;

                    text-decoration: none;
                    color: #1F4546;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    transition: 0.2s;

                    .small-card-elements-button-inner {
                        max-width: 107px;
                        width: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .small-card-elements-button-label {
                            font-family: Graphik Arabic;
                            font-size: 12px;
                            font-weight: 600;
                        }

                        .small-card-elements-button-arrow {
                            img {
                                transition: 0.2s;
                            }
                        }
                    }
                }
            }
        }
    }
}