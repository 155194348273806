.main {
    
    .guide {
        width: 100vw;
        height: 100vh;

        z-index: 4;
        position: fixed;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: 0.2s;

        .guide-background {
            width: 100vw;
            height: 100vh;

            background: rgba(0, 0, 0, 0.50);

            position: fixed;
        }

        .guide-container {
            position: fixed;
            
            z-index: 2;
            width: 577px;
            max-width: 91.8%;
            height: 390px;

            background-color: #FFFFFF;

            display: flex;
            flex-direction: column;
            align-items: center;


            transition: 0.2s;
                
            .guide-title {
                width: 100%;

                margin-top: 48px;

                color: #00B087;
                font-family: Graphik Arabic;
                font-size: 20px;
                font-weight: 600;

                line-height: 16px;

                text-align: center;
            }

            .guide-desc {
                margin-top: 24px;

                width: 100%;

                color: #1C1E27;

                font-family: Graphik Arabic;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                text-align: center;
                
                line-height: 16px;
            }

            .guide-tips {
                margin-top: 40px;

                display: flex;
                align-items: center;
                justify-content: space-between;

                gap: 42px;

                .guide-tip {
                    width: 100%;
                    max-width: 136px;
                    height: 120px;

                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    .guide-tip-image {
                        width: 100%;
                        max-width: 48px;
                        height: 48px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        background-color: #D1EEEA;
                        border-radius: 150px;

                        img {
                            width: 26px;
                        }
                    }

                    .guide-tip-label {
                        color: #1C1E27;

                        text-align: center;
                        font-family: Graphik Arabic;
                        font-size: 13px;
                        
                        font-weight: 400;
                        line-height: 20px;

                        margin-top: 12px;
                    }
                }
            }

            .guide-buttons {
                margin-top: 32px;

                display: flex;
                align-items: center;

                gap: 35px;

                .guide-button {
                    width: 200px;
                    height: 56px;
    
                    background-color: #00B087;
    
                    display: flex;
                    align-items: center;
                    justify-content: center;
    
                    cursor: pointer;
    
                    transition: 0.2s;
    
                    .guide-button-text {
                        color: #FFFFFF;
                        font-family: Graphik Arabic;
                        font-size: 12px;
                        font-weight: 500;
    
                        letter-spacing: 1px;
                        text-transform: uppercase;
                    }
                }

                .guide-button-transparent {
                    border: solid #000000 1px;
                    box-sizing: border-box;

                    background-color: white;

                    .guide-button-text {
                        color: #000000;
                    }
                }
            }
        }
    }

    .guide-mobile {
        width: 100vw;
        height: 100vh;

        z-index: 4;
        position: fixed;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: 0.2s;

        .guide-background {
            width: 100vw;
            height: 100vh;

            background: rgba(0, 0, 0, 0.50);

            position: fixed;
        }

        .guide-container {
            position: fixed;
            
            z-index: 2;
            width: 577px;
            max-width: 91.8%;
            height: 410px;

            background-color: #FFFFFF;

            display: flex;
            justify-content: center;

            transition: 0.2s;

            .guide-container-inner {
                width: 330px;
                max-width: 91.1%;
                height: 100%;

                display: flex;
                align-items: center;
                flex-direction: column;
                
                .guide-title {
                    width: 100%;

                    margin-top: 48px;

                    color: #00B087;
                    font-family: Graphik Arabic;
                    font-size: 20px;
                    font-weight: 600;

                    line-height: 16px;

                    text-align: center;
                }

                .guide-desc {
                    margin-top: 24px;

                    width: 100%;

                    color: #1C1E27;

                    font-family: Graphik Arabic;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    text-align: center;
                    
                    line-height: 16px;
                }

                .guide-tips {
                    margin-top: 40px;

                    width: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .guide-tip {
                        width: 100%;
                        max-width: 136px;
                        height: 100px;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex-direction: column;

                        .guide-tip-image {
                            width: 48px;
                            height: 48px;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            background-color: #D1EEEA;
                            border-radius: 150px;
                        }

                        .guide-tip-label {
                            color: #1C1E27;

                            text-align: center;
                            font-family: Graphik Arabic;
                            font-size: 16px;
                            
                            font-weight: 400;
                            line-height: 20px;
                        }
                    }
                }

                .guide-buttons {
                    margin-top: 48px;
                    width: 100%;

                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    
                    .guide-button {
    
                        width: 100%;
                        height: 56px;
    
                        background-color: #00B087;
    
                        display: flex;
                        align-items: center;
                        justify-content: center;
    
                        cursor: pointer;
    
                        transition: 0.2s;
    
                        .guide-button-text {
                            color: #FFFFFF;
                            font-family: Graphik Arabic;
                            font-size: 12px;
                            font-weight: 500;
    
                            letter-spacing: 1px;
                            text-transform: uppercase;
                        }
                    }
    
                    .guide-button-transparent {
                        border: solid #000000 1px;
                        box-sizing: border-box;
    
                        background-color: white;
    
                        .guide-button-text {
                            color: #000000;
                        }
                    }
                }
            }
        }
    }
}