.main {
    
    .card {
        width: 100vw;
        height: 100vh;

        position: absolute;
        z-index: 9;
        pointer-events: none;

        display: flex;
        align-items: center;

        .card-container {
            position: fixed;
            right: 1.5%;
            
            max-width: 380px;
            width: 100%;

            transition: 0.2s;

            background-color: #FFFFFF;

            .card-exit {
                position: fixed;

                right: 20px;
                top: 20px;

                cursor: pointer;

                img {
                    width: 17px;

                    transition: 0.2s;
                }
            }

            .card-deyaar-circle {
                position: absolute;
                right: 0;
                top: 85px;

                img {
                    width: 80px;
                    border-radius: 7.5px;
                }
            }

            .card-top {
                height: 165px;

                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: flex-end;

                background-color: #2C4C53;
                transition: 0.2s;
                
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;

                .card-top-circles {
                    display: flex;
                    margin-bottom: 20px;

                    position: fixed;

                    .card-top-circle {
                        width: 10px;
                        height: 10px;
                        margin-right: 7px;

                        border-radius: 100px;

                        transition: 0.2s;
                        cursor: pointer;

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }

                .card-top-back {
                    width: 100%;
                    height: 100%;

                    cursor: nesw-resize;
                }
            }

            .card-bottom {
                max-width: 87.5%;
                width: 100%;

                margin: 0 auto;
                margin-top: 15px;
                margin-bottom: 20px;
                
                .card-title {
                    max-width: calc(100% - 6.25% - 60px);
                    margin-bottom: 12px;

                    display: inline-flex;

                    text-decoration: none;
                    color: #000000;
                    
                    font-family: Helvetica;
                    font-size: 28px;
                    font-weight: 300;
                }


                .card-desc {
                    font-family: Helvetica;
                    font-size: 14px;
                    font-weight: 300;

                    line-height: 140%;
                }

                .card-bottom-buttons {
                    max-width: 100%;
                    width: 100%;

                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }

                .card-bottom-button {
                    width: 100%;
                    height: 38px;

                    margin-top: 15px;

                    border: 1px solid #1F4546;
                    
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    cursor: pointer;
                    transition: 0.2s;

                    .card-bottom-button-inner {
                        width: 100%;
                        max-width: calc(100% - 30px);
                        
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        color: #1F4546;
                        font-family: Graphik Arabic;
                        font-weight: 600;
                        font-size: 12px;
                        text-transform: uppercase;

                        user-select: none;

                        img {
                            width: 11px;

                            transition: 0.2s;
                        }
                    }
                }
            }
        }
    }
}