.main {
    .header {
        width: 100%;

        z-index: 3;
        position: fixed;
        top: 0;

        pointer-events: none;
        user-select: none;

        transition: 0.2s;

        .header-circle {
            position: absolute;
            right: 0;
            top: 0;
        }

        .header-logo {
            max-width: 160px;
            width: 100%;

            cursor: pointer;

            position: fixed;
            top: 20px;
            left: 20px;

            .header-logo-top {
                // margin-bottom: 6px;

                img {
                    height: 45px;
                    pointer-events: all;
                }
            }

            .header-logo-bottom {
                font-size: 13px;
                font-weight: 700;
                text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);

                color: #ffffff;

                pointer-events: all;
            }
        }

        .header-container {
            max-width: 97%;
            width: 100%;

            margin: 0 auto;
            margin-top: 20px;

            display: flex;
            align-items: center;
            justify-content: center;

            .header-right {
                max-width: 620px;
                width: 100%;

                display: flex;
                // align-items: center;
                justify-content: space-between;

                margin-right: 200px;

                .header-dropdown {
                    max-width: 220px;
                    width: 100%;
                    display: block;

                    pointer-events: all;

                    transition: 0.2s;

                    .header-dropdown-button {
                        width: 100%;
                        height: 43px;

                        border: 1px solid #e0dee3;
                        background: #fff;
                        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.12);

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        transition: 0.2s;

                        cursor: pointer;
                        position: relative;

                        .header-dropdown-button-inner {
                            width: 85%;

                            display: flex;
                            align-items: center;
                            justify-content: space-between;

                            .header-dropdown-button-text {
                                font-size: 12px;

                                color: #1f4546;

                                b {
                                    color: #ffffff;
                                }
                            }

                            img {
                                width: 12px;
                                height: 10px;

                                transition: 0.2s;
                            }
                        }
                    }

                    .header-dropdown-list {
                        position: absolute;
                        z-index: -1;

                        max-width: 220px;
                        width: 100%;

                        // background-color: rgb(255, 255, 255);
                        // border-radius: 15px;

                        border: 1px solid #e0dee3;
                        background: rgba(0, 0, 0, 0.2);

                        transition: 0.2s ease-out;

                        .header-dropdown-list-title {
                            width: 85%;
                            height: 31px;

                            margin: 0 auto;

                            font-size: 11px;

                            padding-top: 14px;
                            box-sizing: border-box;

                            // color: #000000;
                            color: #ffffff;
                        }

                        .header-dropdown-list-element {
                            width: 100%;

                            font-size: 12px;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            cursor: pointer;
                            transition: 0.2s;

                            // color: #000000;
                            color: #ffffff;

                            .header-dropdown-list-element-inner {
                                width: 85%;
                                display: flex;
                                align-items: center;

                                .header-dropdown-list-radio-button {
                                    width: 15px;
                                    height: 15px;

                                    // border: 1px solid rgb(63, 63, 63);
                                    border: 1px solid rgb(255, 255, 255);
                                    border-radius: 100px;
                                    box-sizing: border-box;

                                    margin-right: 12px;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .header-dropdown-list-radio-button-inner {
                                        width: 9px;
                                        height: 9px;

                                        // background-color: #a15652;
                                        background-color: #ffffff;
                                        border-radius: 100px;

                                        transition: 0.2s;
                                    }
                                }

                                .header-dropdown-list-checkbox-button {
                                    width: 16px;
                                    height: 16px;

                                    // border: 1px solid rgb(63, 63, 63);
                                    border: 1px solid rgb(255, 255, 255);
                                    border-radius: 1px;
                                    box-sizing: border-box;

                                    margin-right: 12px;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .header-dropdown-list-checkbox-button-inner {
                                        width: 10px;
                                        height: 10px;

                                        // background-color: #a15652;
                                        background-color: rgba(77, 172, 137, 1);
                                        border-radius: 1px;

                                        transition: 0.2s;
                                    }
                                }
                            }

                            &:last-child {
                                // border-radius: 0 0 15px 15px;
                                // border-radius: 0 0 8px 8px;
                            }
                        }
                    }
                }

                .header-right-switchbox {
                    height: 48px;
                    // width: 100%;

                    display: flex;
                    // align-items: left;
                    flex-direction: column;
                    justify-content: space-between;
                }

                .header-switch {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    pointer-events: all;

                    height: 24px;

                    .header-switch-inner {
                        width: 100%;

                        display: flex;
                        align-items: center;

                        cursor: pointer;

                        .header-switch-element {
                            margin-right: 15px;
                        }

                        .header-switch-label {
                            font-size: 13px;
                            color: rgba(31, 69, 70, 1);
                        }
                    }
                }

                .header-button {
                    max-width: 210px;
                    width: 100%;
                    height: 41px;

                    backdrop-filter: blur(10px);
                    box-shadow: inset 0 0 10px 5px rgba(255, 255, 255, 0.02);
                    border: 1px solid #e6e2e2e5;
                    border-radius: 8px;

                    transition: 0.2s;

                    cursor: pointer;
                    pointer-events: all;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    font-size: 12px;
                    color: #ffffff;

                    .header-button-inner {
                        max-width: 55%;
                        width: 100%;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        img {
                            width: 16px;

                            transition: 0.3s;
                        }
                    }
                }
            }

            .header-panel {
                display: flex;
                // align-items: center;
                justify-content: center;

                max-width: 377px;
                width: 100%;

                .header-panel-top {
                    pointer-events: all;

                    position: absolute;
                    z-index: 3;

                    max-width: 377px;
                    width: 100%;
                    height: 45px;

                    background: #fff;
                    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    padding: 0 16px 0 16px;
                    box-sizing: border-box;

                    .header-dropdown-button {
                        height: 45px;

                        display: flex;
                        align-items: center;
                        justify-content: center;

                        transition: 0.2s;

                        cursor: pointer;
                        position: relative;

                        .header-dropdown-button-text {
                            color: #00b087;
                            font-family: Graphik Arabic;
                            font-size: 13px;
                            font-weight: 400;
                            line-height: 16px;

                            margin-right: 9px;

                            b {
                                color: #ffffff;
                            }
                        }

                        img {
                            width: 12px;
                            height: 10px;

                            transition: 0.2s;
                        }
                    }

                    .header-panel-top-tip {
                        width: 24px;
                        height: 24px;

                        cursor: pointer;
                        transition: 0.2s;
                    }
                }

                .header-panel-bottom {
                    pointer-events: all;

                    max-width: 377px;
                    width: 100%;

                    display: flex;
                    justify-content: center;

                    .header-dropdown-list {
                        position: absolute;
                        z-index: 1;

                        max-width: 377px;
                        width: 100%;

                        // background-color: rgb(255, 255, 255);
                        // border-radius: 15px;

                        // border: 1px solid #E0DEE3;
                        // background: rgba(0, 0, 0, 0.20);

                        background: #fff;
                        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

                        transition: 0.25s ease-out;

                        padding: 8px 0 8px 0;
                        box-sizing: border-box;

                        transform-origin: top;

                        .header-dropdown-list-element {
                            width: 100%;
                            height: 54px;

                            font-size: 12px;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            cursor: pointer;
                            transition: 0.2s;

                            color: #1c1e27;
                            font-family: Graphik Arabic;
                            font-size: 13px;
                            font-weight: 400;

                            transform-origin: top;

                            &:last-child {
                                .header-dropdown-list-element-inner {
                                    border: 0;
                                }
                            }

                            .header-dropdown-list-element-inner {
                                width: 95.7%;
                                height: 100%;

                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                border-bottom: 1px solid #e8e8e9;

                                box-sizing: border-box;
                                padding: 0 8px 0 8px;

                                .header-dropdown-list-checkbox-button {
                                    width: 16px;
                                    height: 16px;

                                    // border: 1px solid rgb(63, 63, 63);
                                    border: 1px solid rgb(255, 255, 255);
                                    border-radius: 1px;
                                    box-sizing: border-box;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    .header-dropdown-list-checkbox-button-inner {
                                        width: 10px;
                                        height: 10px;

                                        // background-color: #a15652;
                                        background-color: rgba(77, 172, 137, 1);
                                        border-radius: 1px;

                                        transition: 0.2s;
                                    }
                                }
                            }
                        }
                    }

                    .header-guide {
                        position: fixed;
                        z-index: 1;

                        background: #fff;
                        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);

                        // height: 164px;
                        max-width: 377px;
                        width: 100%;

                        transition: 0.25s;
                        transform-origin: top;

                        padding: 24px 0 24px 0;
                        box-sizing: border-box;

                        display: flex;
                        align-items: center;
                        flex-direction: column;

                        .header-guide-element {
                            max-width: calc(100% - 32px);
                            width: 100%;

                            margin-bottom: 20px;

                            display: flex;
                            align-items: center;

                            &:last-child {
                                margin-bottom: 0;
                            }

                            .header-guide-element-img {
                                min-width: 48px;
                                height: 48px;

                                background-color: #d1eeea;
                                border-radius: 150px;

                                display: flex;
                                align-items: center;
                                justify-content: center;

                                margin-right: 16px;

                                img {
                                    height: 24px;
                                }
                            }

                            .header-guide-element-label {
                                color: #1c1e27;
                                font-family: Graphik Arabic;
                                font-size: 14px;
                                font-weight: 400;
                            }
                        }

                        .header-guide-button {
                            width: 100%;
                            max-width: 140px;
                            height: 38px;
                            background-color: #00b087;

                            display: flex;
                            align-items: center;
                            justify-content: center;

                            cursor: pointer;

                            transition: 0.2s;

                            .header-guide-button-text {
                                color: #ffffff;
                                font-family: Graphik Arabic;
                                font-size: 11px;
                                font-weight: 400;

                                letter-spacing: 1px;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }
}
